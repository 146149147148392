@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: "Avenir LT Std", sans-serif;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Avenir LT Std", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
li,
ul,
button {
  font-family: "Avenir LT Std", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}